import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useParams, Link as backLink } from "react-router-dom";
import {
  Box,
  Text,
  Divider,
  Container,
  Flex,
  Icon,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  HStack,
  Image,
  Badge,
  VStack,
  Heading,
  useClipboard,
  IconButton,
  Tooltip,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Link,
  Button,
} from "@chakra-ui/react";
import { FaGithub, FaLinkedin, FaYoutube, FaInstagram } from "react-icons/fa";
import { ChevronRightIcon, CopyIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import Prism from "prismjs";
import "prismjs/themes/prism-tomorrow.css"; // Example theme
// Import additional languages if needed
import "prismjs/components/prism-javascript";
import "prismjs/components/prism-css";
import "prismjs/components/prism-markup";
import "../../../styles/AbapCustomStyle";
import copy from "copy-to-clipboard";
import { DataContext } from "../../../services/DataContext";

const endLogo = "/images/1-icon.png";

// A component for rendering the breadcrumb
const BlogBreadcrumb = ({ title }) => (
  <Breadcrumb spacing="8px" separator={<ChevronRightIcon color="gray.500" />}>
    <BreadcrumbItem>
      <BreadcrumbLink as={backLink} to="/blog">
        Blog
      </BreadcrumbLink>
    </BreadcrumbItem>
    <BreadcrumbItem isCurrentPage>
      <BreadcrumbLink>{title}</BreadcrumbLink>
    </BreadcrumbItem>
  </Breadcrumb>
);

const ContentBlock = ({ block }) => {
  useEffect(() => {
    Prism.highlightAll();
  }, [block]); // Rerun highlighting when the block changes

  const codeRef = useRef(null);
  const { hasCopied, onCopy } = useClipboard(
    codeRef.current?.textContent || ""
  );

  // Function to copy the code to the clipboard
  const handleCopy = () => {
    if (codeRef.current) {
      copy(codeRef.current.textContent);
      onCopy();
    }
  };

  switch (block.type) {
    case "text":
      if (block.type === "text") {
        // Check if the content should be rendered as HTML
        if (block.textType === "normal" && block.content.includes("<ul>")) {
          return (
            <Box
              mb="20px"
              ml="30px"
              dangerouslySetInnerHTML={{ __html: block.content }}
            />
          );
        } else if (block.textType === "subtitle") {
          return (
            <Text fontSize="xl" fontWeight="bold" mb="20px">
              {block.content}
            </Text>
          );
        } else {
          return (
            <Text fontSize="md" mb="20px">
              {block.content}
            </Text>
          );
        }
      }
      break;
    case "code":
      return (
        <Box position="relative" mb="20px">
          <IconButton
            aria-label="Copy code"
            icon={<CopyIcon />}
            size="sm"
            position="absolute"
            top="1"
            right="1"
            zIndex="1"
            onClick={handleCopy}
          />
          <Tooltip
            label={hasCopied ? "Copied!" : "Copy"}
            closeOnClick={false}
            isOpen={hasCopied}
          >
            <Box
              as="pre"
              ref={codeRef}
              p="4"
              overflowX="auto"
              borderWidth="1px"
              borderColor="gray.200"
              borderRadius="md"
              position="relative"
            >
              <code className={`language-${block.language}`}>
                {block.content}
              </code>
            </Box>
          </Tooltip>
        </Box>
      );
    case "image":
      return (
        <Image
          src={block.src}
          alt={block.alt || "Image"}
          width="100%"
          height="auto"
          maxH="700px"
          objectFit="cover"
          mb="20px"
        />
      );
    case "link":
      return (
        <Box mb="20px">
          <Link
            href={block.linkUrl} // Changed to 'href' for external links
            isExternal
            target="_blank"
            rel="noopener noreferrer"
            color="blue.500" // Set the initial color
            fontWeight="bold" // Make the text bold
            _hover={{ textDecoration: "underline", color: "blue.700" }} // Style for hover: underline and color change
            display="inline-flex" // To align the icon with the text
            alignItems="center" // To align the icon with the text
          >
            <Icon as={ExternalLinkIcon} mr="2" /> {/* Icon before the text */}
            {block.linkText}
          </Link>
        </Box>
      );

    default:
      return null;
  }
};

const BlogFooter = ({ blog }) => {
  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      width="full"
      mt={4}
      px={4}
    >
      <Popover>
        <PopoverTrigger>
          <Box cursor="pointer">
            <Flex alignItems="center">
              <Image
                borderRadius="full"
                boxSize="40px"
                src={blog.author.authorAvatar}
                alt="Author"
              />
              <Text ml={2}>{blog.author.author}</Text>
            </Flex>
          </Box>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader>{blog.author.author}</PopoverHeader>
          <PopoverBody>
            <Text mb={4}>{blog.author.authorTitle}</Text>
            <HStack spacing={4}>
              {blog.author.authorInstagram && (
                <Link
                  href={blog.author.authorInstagram}
                  isExternal
                  target="_blank"
                >
                  <Icon as={FaInstagram} w={5} h={5} />
                </Link>
              )}
              {blog.author.authorYoutube && (
                <Link
                  href={blog.author.authorYoutube}
                  isExternal
                  target="_blank"
                >
                  <Icon as={FaYoutube} w={5} h={5} />
                </Link>
              )}
              {blog.author.authorLinkedin && (
                <Link
                  href={blog.author.authorLinkedin}
                  isExternal
                  target="_blank"
                >
                  <Icon as={FaLinkedin} w={5} h={5} />
                </Link>
              )}
              {blog.author.authorGithub && (
                <Link
                  href={blog.author.authorGithub}
                  isExternal
                  target="_blank"
                >
                  <Icon as={FaGithub} w={5} h={5} />
                </Link>
              )}
            </HStack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
      <Text>
        {new Date(
          new Date(blog.date).getTime() +
            new Date(blog.date).getTimezoneOffset() * 60000
        ).toLocaleDateString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
        })}
      </Text>
    </Flex>
  );
};

const BlogPage = () => {
  const { path } = useParams();
  const { blogData, authorData } = useContext(DataContext);

  const blog = useMemo(() => {
    const foundBlog = blogData.find((t) => t.id === path);
    if (foundBlog && authorData) {
      // Find the author with the matching ID
      const author = authorData.find((a) => a.id === foundBlog.authorID) || {};
      return { ...foundBlog, author }; // Combine blog with its author
    }
    return null;
  }, [path, blogData, authorData]);

  if (!blog) {
    return (
      <Box p="4">
        <Text>No blog found.</Text>
      </Box>
    );
  }

  return (
    <Container
      maxW={{ base: "100%", sm: "600px", md: "600px", lg: "600px" }}
      p="2rem"
    >
      <BlogBreadcrumb title={blog.shortTitle} />
      <VStack spacing="4" align="left" textAlign="left">
        <Heading as="h1" mt="20px" mb="0">
          {blog.title}
        </Heading>
        <Divider />
        <HStack
          justifyContent="center"
          spacing={4}
          mt={blog.tVideo || blog.tCode ? "4" : "-20px"}
          mb={4}
          width="100%"
        >
          {blog.tVideo && (
            <Badge colorScheme="blue" display="flex" alignItems="center">
              <Icon as={FaYoutube} w={4} h={4} mr={2} color="red.500" />
              <Box as="span" lineHeight="inherit">
                Video Available
              </Box>
            </Badge>
          )}

          {blog.tCode && (
            <Badge colorScheme="green" display="flex" alignItems="center">
              <Icon as={FaGithub} w={4} h={4} mr={2} color="black" />
              <Box as="span" lineHeight="inherit">
                Code Repository Available
              </Box>
            </Badge>
          )}
        </HStack>
        <Box w="full" overflow="hidden">
          {blog.content.map((block, index) => (
            <ContentBlock key={index} block={block} />
          ))}
        </Box>
        <HStack spacing={8} justifyContent="center" width="100%" mt={4}>
          {blog.tVideo && (
            <Tooltip hasArrow label="Watch the video" aria-label="Tooltip">
              <Link to={blog.videoURL} isExternal>
                <Icon as={FaYoutube} w={10} h={10} color="red.500" />
              </Link>
            </Tooltip>
          )}
          {blog.tCode && (
            <Tooltip
              hasArrow
              label="View the code repository"
              aria-label="Tooltip"
            >
              <Link to={blog.codeURL} isExternal>
                <Icon as={FaGithub} w={10} h={10} color="black" />
              </Link>
            </Tooltip>
          )}
        </HStack>
        <BlogFooter blog={blog} />
        <Flex justifyContent="center" width="full" my="20px">
          <Image
            src={endLogo}
            alt="Company Logo"
            w="100px"
            h="auto"
            objectFit="contain"
            marginTop="10px"
          />
        </Flex>
      </VStack>
    </Container>
  );
};

export default BlogPage;
