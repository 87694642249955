import React, { useEffect, useState } from "react";
import {
  Flex,
  IconButton,
  useColorMode,
  useColorModeValue,
  useDisclosure,
  Link as ChakraLink,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  VStack,
  Box,
  HStack,
  Image,
  keyframes,
  Spacer,
  Divider,
} from "@chakra-ui/react";
import { SunIcon, MoonIcon, HamburgerIcon } from "@chakra-ui/icons";
import { useNavigate, useLocation, Link } from "react-router-dom";

const slideIn = keyframes`
  from { transform: translateX(100%); opacity: 0; }
  to { transform: translateX(0); opacity: 1; }
`;

const slideOut = keyframes`
  from { transform: translateX(0); opacity: 1; }
  to { transform: translateX(100%); opacity: 0; }
`;

const NavbarLink = ({ name, href, onClose }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const isActive = location.pathname === href;

  const hoverBg = useColorModeValue(
    "hoverLink.light.backgroundColor",
    "hoverLink.dark.backgroundColor"
  );
  const hoverColor = useColorModeValue(
    "hoverLink.light.color",
    "hoverLink.dark.color"
  );

  const activeBg = isActive ? hoverBg : "transparent";
  const activeColor = isActive ? hoverColor : undefined;

  const handleClick = () => {
    navigate(href);
    if (typeof onClose === 'function') {
      onClose();
    }
  };

  return (
    <ChakraLink
      onClick={handleClick}
      px={2}
      py={1}
      rounded={"md"}
      bg={activeBg}
      color={activeColor}
      _hover={{
        textDecoration: "none",
        backgroundColor: hoverBg,
        color: hoverColor,
      }}
      style={{ zIndex: 10 }}
    >
      {name}
    </ChakraLink>
  );
};

const Navbar = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navbarLogo = "images/4-icon.png";
  const [show, setShow] = useState(false);

  const animation = show
    ? `${slideIn} 0.5s ease forwards`
    : `${slideOut} 0.5s ease forwards`;

  const navbarBgColor = useColorModeValue(
    "navbar.light.backgroundColor",
    "navbar.dark.backgroundColor"
  );
  const navbarTextColor = useColorModeValue(
    "navbar.light.color",
    "navbar.dark.color"
  );

  const controlNavbar = () => {
    if (window.scrollY > 50) {
      setShow(true);
    } else {
      setShow(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", controlNavbar);
    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  }, []);

  return (
    <>
      <Flex
        as="nav"
        align="center"
        wrap="wrap"
        p="0.5rem 1.5rem"
        bg="transparent"
        color={useColorModeValue("navbar.light.color", "navbar.dark.color")}
        top={0}
        zIndex={1}
        position={{ base: "sticky", md: show ? "relative" : "sticky" }}
        transition="top 0.5s"
        style={{ top: show ? "-80px" : "0" }}
        maxW="1200px"
        mx="auto"
        justifyContent={{ base: "space-between", md: "flex-start" }}
      >
        <Box> 
          <Link to="/">
            <Image src={navbarLogo} alt="Logo" />
          </Link>
        </Box>
        <Spacer />
        <Box flex="1">
        <Flex
          flex={{ base: 1, md: 1, lg: 3 }}
          justify="left"
          align="left"
          minWidth="0"
        >
          <HStack
             spacing={4}
             display={{ base: "none", md: "flex" }}
             align="center"
             flex={1}
             justifyContent="center"
          >
            <NavbarLink name="Home" href="/" />
            <NavbarLink name="Learning" href="/learning" />
            <NavbarLink name="Blog" href="/blog" />
          </HStack>
        </Flex>
        </Box>
        <Spacer />
        <IconButton
          icon={colorMode === "light" ? <MoonIcon /> : <SunIcon />}
          onClick={toggleColorMode}
          isRound
          size="md"
          aria-label="Toggle Color Mode"
          display={{ base: "none", md: "flex" }}
        />

        <IconButton
          icon={<HamburgerIcon />}
          size="md"
          aria-label="Open Menu"
          display={{ base: "flex", md: "none" }}
          onClick={onOpen}
          ml="auto"
        />
      </Flex>

      <IconButton
        icon={<HamburgerIcon />}
        size="md"
        aria-label="Open Menu"
        position="fixed"
        top="1rem"
        right="1.5rem"
        zIndex="2"
        onClick={onOpen}
        display={{ base: "none", md: show ? "flex" : "none" }}
        animation={animation}
      />

      <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody>
            <Flex direction="column" h="full">
              <VStack mt={10} spacing={4} align="center">
                <NavbarLink name="Home" href="/" onClose={onClose}/>
                <NavbarLink name="Learning" href="/learning" onClose={onClose}/>
                <NavbarLink name="Blog" href="/blog" onClose={onClose}/>
              </VStack>
              <Spacer />
              <Divider my="20px" />
              <Box
                pt="20px"
                pb="10px"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <IconButton
                  icon={colorMode === "light" ? <SunIcon /> : <MoonIcon />}
                  onClick={toggleColorMode}
                  isRound
                  size="md"
                  aria-label="Toggle Color Mode"
                />
              </Box>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default Navbar;