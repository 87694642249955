import { IconButton, keyframes } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { ChevronUpIcon } from "@chakra-ui/icons";

const slideIn = keyframes`
  from { transform: translateX(100%); opacity: 0; }
  to { transform: translateX(0); opacity: 1; }
`;

const slideOut = keyframes`
  from { transform: translateX(0); opacity: 1; }
  to { transform: translateX(100%); opacity: 0; }
`;

const BackToTopButton = () => {
  const [show, setShow] = useState(false);

  const checkScrollTop = useCallback(() => {
    setShow(window.scrollY > 80);
  }, []);

  const animation = show
    ? `${slideIn} 0.5s ease forwards`
    : `${slideOut} 0.5s ease forwards`;

  useEffect(() => {
    window.addEventListener("scroll", checkScrollTop);
    return () => {
      window.removeEventListener("scroll", checkScrollTop);
    };
  }, [checkScrollTop]);

  return (
    show && (
      <IconButton
        icon={<ChevronUpIcon />}
        onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
        position="fixed"
        bottom={{ base: "30px", md: "30px" }}
        right={{ base: "12px", md: "25px" }}
        borderRadius="full"
        height="20px"
        width="20px"
        size={{ base: "sm", md: "md" }}
        color="#ffffff"
        bg="#18D9D9"
        zIndex="10"
        animation={animation}
      />
    )
  );
};

export default BackToTopButton;
