import React from 'react';
import { Box, Heading } from '@chakra-ui/react';
import './Home.css';
import '../../App.css' // Assuming you have a CSS file for styles

function Home() {
  return (
    <Box className="home">
      <Box className="contentBox" m={{ base: '20px', md: '0' }}>
        <Heading as="h1" size="2xl" mb="4" color="#18D9D9">
          Spatium Tech
        </Heading>
        <div class="gradient-text">
          Expanding Horizons in Code - Where Every Line Leads to Discovery!
        </div>
      </Box>
    </Box>
  );
}

export default Home;
